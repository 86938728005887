import { Chart } from "react-google-charts";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export const options = {
    chart: {
        title: "Температура и влажность в помещении",
    },
    width: 900,
    height: 500,
    series: {
        // Gives each series an axis name that matches the Y-axis below.
        0: { axis: "Temps" },
        1: { axis: "Humidity" },
    },
    axes: {
        // Adds labels to each axis; they don't have to match the axis names.
        y: {
            Temps: { label: "Температура" },
            Humidity: { label: "Влажность" },
        },
    },
};

const charts = (props: {
    //datas: any[]
    times: any[],
    columns: string[],
    data: any[]
}) => {
    const { times, columns, data } = props;

    const datas: any = [
        [{ type: "date", label: "Время" }/*, "Температура в помещении", "Влажность в помещении"*/],
    ];

    for (let i = 0; i < columns.length; i++) {
        datas[0].push(columns[i]);
    }

    for (let i = 0; i < times.length; i++) {
        //data.push([new Date(datas[i]['time']), Number(datas[i]['value']), Number(datas[i]['value']) * 0.1]);
        datas.push([new Date(times[i])]);
        for (var j = 0; j < columns.length; j++) {
            datas[i + 1].push(Number(data[j][i]));
        }
    }

    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    console.log(data);

    //const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}, ...];
    let datas2: any[] = [];
    for (let i = 0; i < times.length; i++) {
        datas2.push({ time: times[i], 'Температура в помещении': Math.round(Number(data[0][i])), 'Влажность в помещении': Math.round(Number(data[1][i])) });
    }
    datas2.reverse();
    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    console.log(datas2);


    return (
        <>
            {datas.length > 0 && (
                <>

                    <ResponsiveContainer width="100%" height={600}>
                        <LineChart width={400} height={400} data={datas2} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                            <XAxis dataKey="time" />
                            <Tooltip />
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                            <Line type="monotone" dataKey="Температура в помещении" stroke="#ff7300" yAxisId={0} />
                            <Line type="monotone" dataKey="Влажность в помещении" stroke="#387908" yAxisId={1} />
                            <YAxis yAxisId={0} orientation='left' domain={['dataMin', 'dataMax']} />
                            <YAxis yAxisId={1} orientation='right' domain={['dataMin', 'dataMax']} />
                            <Legend />
                        </LineChart>
                    </ResponsiveContainer>

                    <Chart
                        chartType="Line"
                        data={datas}
                        options={options}
                        width={"100%"}
                        height={"400px"}
                    />
                </>
            )}
        </>
    );
};
export default charts;