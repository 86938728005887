import React, { useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import Chart from './components/chart'
import { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  //const [count, setCount] = useState(0);
  //const [datas, setDatas] = useState<any[]>([]);
  const [loaded, setLoaded] = useState(false);
  const _columns = useRef<any[]>([]);
  const _data = useRef<any[]>([]);
  const _times = useRef<any[]>([]);

  const load = async () => {
    let humidity = await axios.get("http://tags.paranet.ru/load_tag_datas.php?id=FZ-01-Humidity"); //FZ-01-Humidity
    let temperature = await axios.get("http://tags.paranet.ru/load_tag_datas.php?id=FZ-01-TEMPERATURE")

    // let data: any[] = [];
    // data.push(temperature.data);
    // data.push(humidity.data);
    // console.log(data);
    console.log("------------------------------------------");

    let times: any[] = [];
    for (let i = 0; i < temperature.data.length; i++) {
      times.push(temperature.data[i]['time']);
    }
    let data: any[] = [];
    data.push([]);
    for (let i = 0; i < temperature.data.length; i++) {
      data[0].push(temperature.data[i]['value']);
    }
    data.push([]);
    for (let i = 0; i < humidity.data.length; i++) {
      data[1].push(humidity.data[i]['value']);
    }
    let columns: string[] = ["Температура в помещении", "Влажность в помещении"];

    //setDatas(data);
    _columns.current = columns;
    console.log(columns);
    _data.current = data;
    console.log(data);
    _times.current = times;
    console.log(times);

    setLoaded(true);

    // const response = await axios
    //   .get("http://tags.paranet.ru/load_tag_datas.php?id=FZ-01-TEMPERATURE") //FZ-01-Humidity
    //   .then((result) => {
    //     setDatas(result.data);
    //     console.log('updated');
    //     if (datas.length > 0) {
    //       setLoaded(true);
    //     }
    //   });
  };

  const handleClick = async () => {
    //load();
    setLoaded(false);
  }

  useEffect(() => {
    if (!loaded) {
      load();
    }
  }/*, [show]*/);

  return (
    <>
      <div className="App">
        <div>
          <button onClick={handleClick}>
            Обновить...
          </button>
        </div>
        {_times.current.length > 0 && (
          <Chart
            times={_times.current}
            columns={_columns.current}
            data={_data.current}
          />
        )}
      </div>
    </>
  );
}

export default App;
